import * as joint from '@joint/core';

interface FlowEditorOptions {
    container: HTMLElement;
    width: number;
    height: number;
    dotNetHelper: any;
    futureStateMode: boolean;
}

interface FlowPreviewOptions {
    container: HTMLElement;
    graphJson: string;
}

enum ShapeType {
    CorporationCFC = 1,
    BranchThirdParty = 2,
    Partnership = 3,
    HybridEntity = 4,
    ReverseHybridEntity = 5,
    DRE = 6,
    Group = 7,
}

enum TransactionType {
    ServiceFee,
    Royalty,
    IPTransfer,
}

enum FlowType {
    Physical,
    Legal,
    Payment,
    Service,
    IPTransfer,
    IPLicense,
    Agreement,
    Miscellaneous,
}

enum Jurisdiction {
    US = 1,
    NonUS = 2,
    Mixed = 3,
}

enum PaymentType {
    Royalty = 0,
    ServiceFee = 1,
    BuySell = 2,
}

class DefaultValues {
    static readonly PrimaryColor: string = '#000000';
    static readonly SecondaryColor: string = '#0D838F';
    static readonly EmphasizedColor: string = '#87BC24';
    static readonly ThirdPartyColor: string = '#52575B';
}

type LinkTypeColor = {
    type: string;
    color: string;
};

const linkTypeColors: readonly LinkTypeColor[] = [
    { type: 'PhysicalLink', color: '#CE1210' },
    { type: 'LegalLink', color: '#99C13C' },
    { type: 'PaymentLink', color: '#9B9B9D' },
    { type: 'ServiceLink', color: 'black' },
    { type: 'IPTransferLink', color: '#FFA500' },
    { type: 'IPLicenseLink', color: '#8E25AB' },
    { type: 'AgreementLink', color: '#256F8C' },
    { type: 'MiscellaneousLink', color: '#EB8100' }
];

class Node extends joint.dia.Element {
    //_entityIds: string[] = [];
    get entityIds() {
        return this.prop('entityIds');
    }
    set entityIds(value: string[]) {
        this.prop('entityIds', value);
    }

    get isNewEntity() {
        return this.prop('isNewEntity');
    }
    set isNewEntity(value: boolean) {
        this.prop('isNewEntity', value);
    }

    public setColor(color: string) {
        this.attr('body/fill', color);
    }

    get functions() {
        return this.prop('functions');
    }
    set functions(value: string[]) {
        this.removeProp('functions');
        this.prop('functions', value);
    }

    public setColorByJurisdiction(jurisdiction: Jurisdiction) {
        switch (jurisdiction) {
            case Jurisdiction.US:
                this.setColor(DefaultValues.PrimaryColor);
                break;
            case Jurisdiction.NonUS:
                this.setColor(DefaultValues.SecondaryColor);
                break;
            case Jurisdiction.Mixed: // overridden in EntityGroup
                this.setColor(DefaultValues.PrimaryColor);
                break;
            default:
                this.setColor(DefaultValues.PrimaryColor);
                break;
        }
    }

    public setLabel(label: string) {
        // if the string is too long, split on all spaces and add a newline where the string exceeds a certain length
        if (label.length > 15) {
            var splitLabel = '';
            var words = label.split(' ');
            var lineLength = 0;
            words.forEach(word => {
                if (lineLength + word.length > 15) {
                    splitLabel += '\n' + word + ' ';
                    lineLength = word.length;
                } else {
                    splitLabel += word + ' ';
                    lineLength += word.length;
                }
            });

            this.attr('label/text', splitLabel.trim());
        } else {
            this.attr('label/text', label);
        }
    }

    public setSecondaryLabel(label: string) {
        // if the string is too long, split on all spaces and add a newline where the string exceeds a certain length
        if (label.length > 15) {
            var splitLabel = '';
            var words = label.split(' ');
            var lineLength = 0;
            words.forEach(word => {
                if (lineLength + word.length > 15) {
                    splitLabel += '\n' + word + ' ';
                    lineLength = word.length;
                } else {
                    splitLabel += word + ' ';
                    lineLength += word.length;
                }
            });
            this.attr('label2/text', splitLabel.trim());
        } else {
            this.attr('label2/text', label);
        }
    }

    public async setFunctionIcon(iconName: string): Promise<void> {
        try {
            const iconPath = `/icons/functions/${iconName}.svg`;

            const response = await fetch(iconPath);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const svgContent = await response.text();
            const encodedSvg = encodeURIComponent(svgContent);

            this.attr({
                functionIcon: {
                    'xlink:href': `data:image/svg+xml;utf8,${encodedSvg}`
                }
            });
        } catch (error) {
            console.error('Failed to load SVG:', error);
        }
    }

    public async addFunctionIcons(functions: string[]): Promise<void> {
        console.debug("old functions: ", this.functions)
        console.debug("new functions: ", functions)
        this.functions = functions;
        const iconSize = 30;
        const padding = 5;
        const elementWidth = 170;
        const elementHeight = 100;

        //// Check if there is already a functionIcon with a non-empty href
        //const existingIconHref = this.attr('functionIcon/xlink:href');
        //let startX;
        //let startY = iconSize;

        //if (existingIconHref && existingIconHref !== '') {
        //    // If there's an existing icon, start to the left of it
        //    startX = elementWidth - (functions.length * iconSize + (functions.length - 1) * padding) - padding;
        //} else {
        //    // If no existing icon, start from the left side of the element
        //    startX = (iconSize / 2);
        //}

        //for (let i = 0; i < functions.length; i++) {
        //    const functionName = functions[i];

        //    try {
        //        const iconPath = `/icons/functions/${functionName}.svg`;

        //        const response = await fetch(iconPath);
        //        if (!response.ok) {
        //            throw new Error(`HTTP error! Status: ${response.status}`);
        //        }
        //        const svgContent = await response.text();
        //        const encodedSvg = encodeURIComponent(svgContent);

        //        const refX = (startX / elementWidth) * 100 + '%';
        //        const refY = (startY / elementHeight) * 100 + '%';

        //        this.attr({
        //            [`functionIcon${i}`]: {
        //                'xlink:href': `data:image/svg+xml;utf8,${encodedSvg}`,
        //                width: iconSize,
        //                height: iconSize,
        //                refX: refX,
        //                refY: refY,
        //            }
        //        });

        //        startX += iconSize + padding;

        //    } catch (error) {
        //        console.error(`Failed to load SVG for ${functionName}:`, error);
        //    }
        //}
    }
}

interface EntityNodeOptions {
    jurisdiction: Jurisdiction;
    shape: ShapeType;
    label: string;
    entityIds: string[];
    functions: string[];
    isNewEntity: boolean;
}

interface MenuOption {
    text: string;
    action: () => void | any;
    subMenu?: MenuOption[];
}

enum ToolMode {
    AddFlow,
    AddTransaction,
    DrawRectangle,
    MoveExpense,
    MoveRevenue,
    MoveFunction,
}

export class FlowEditor {
    private readonly namespace = joint.shapes;
    private paper: joint.dia.Paper;
    private graph: joint.dia.Graph;
    private dotNetHelper: any;
    private readonly futureStateMode: boolean;
    private readonly container: HTMLElement;

    // context variables
    private activeTool: ToolMode | null = null;
    // for adding flows
    private flowTypeToCreate: FlowType | null = null;
    // for adding transactions
    private transactionTypeToCreate: TransactionType | null = null;
    // for adding flows or transactions
    private selectedElement: joint.dia.Element | null = null;
    // for moving functions
    private functionNameToMove: string | null = null;
    // for drawing rectangles
    private startRectangle: { x: number, y: number } | null = null;
    private tempRectangleElement: DrawnRectangle | null = null;
    // for panning the paper
    private panStartPosition: { x: number, y: number } | null = null;


    public getGraphJson() {
        var graphJson = JSON.stringify(this.graph?.toJSON());
        return graphJson;
    }


    public setGraphJson(graphJson: string) {
        console.debug('setGraphJson');
        this.closeMenus();

        if (graphJson) {
            console.debug('loading graph from JSON');
            this.paper.model.fromJSON(JSON.parse(graphJson), { cellNamespace: this.namespace });

            this.graph.getElements().forEach(element => {
                if (element instanceof Node) {
                    let markup = element.get('markup');

                    if (!Array.isArray(markup)) {
                        markup = [markup];
                    }

                    // clean-up unused function icon markup
                    const newMarkup = markup.filter(markupElement =>
                        typeof markupElement === 'object' && 'tagName' in markupElement && markupElement.tagName !== 'image' && !markupElement.selector.includes('functionIcon')
                    );

                    element.set('markup', newMarkup);

                    // backwards compatibility check for entity ids with spaces in them (in case flows were created before spaces were disallowed)
                    const entityIds = element.entityIds;
                    if (entityIds.some(id => id.includes(' '))) {
                        element.entityIds = entityIds.map(id => id.replace(' ', '_'));
                    }

                    // backwards compatibility check for populating flow legend with entities created before svgPath property was defined
                    if (element.get('type') !== null && !element.get('svgPath')) {
                        const entityType = element.get('type');
                        const svgPath = `/icons/${entityType}.svg`;
                        element.set('svgPath', svgPath);
                    }
                }
            });

            // TEMP workaround for populating flow legend with links created before custom link classes were defined
            this.graph.getLinks().forEach(link => {

                // delete instances of MiscellaneousLink
                if (link instanceof MiscellaneousLink) {
                    link.remove();
                    return;
                }
                // and check legacy flows by colorMiscellaneousLinks
                else if (link.attributes.type === 'standard.Link' && link.attributes.attrs?.line?.stroke === '#EB8100') {
                    link.remove();
                    return;
                }

                if (link.attributes.type === 'standard.Link' && link.attributes.attrs?.line?.stroke) {
                    const linkColor = link.attributes.attrs.line.stroke;
                    const matchingLink = linkTypeColors.find(lc => lc.color === linkColor);

                    if (matchingLink) {
                        link.attributes.color = linkColor;
                        link.attributes.type = matchingLink.type;
                    }
                }

            });

            this.paper.scale(1, 1);
            this.paper.transformToFitContent({ verticalAlign: 'middle', horizontalAlign: 'middle', minScale: 1, maxScale: 1 });
        } else {
            console.debug('creating new graph');
            this.paper.model.clear();
        }
        console.debug('setGraphJson done');
    }



    // Invoked by C#
    private setRectangleMode(value: boolean) {
        console.debug('rectangle mode ' + value)
        this.activeTool = value ? ToolMode.DrawRectangle : null;
    }

    private drawRectangle(evt: joint.dia.Event, x: number, y: number) {
        if (this.startRectangle) {
            console.debug('drawing rectangle')
            this.tempRectangleElement?.remove();

            // Create the rectangle shape (if it is big enough to be visible, based on gridSize)
            if (Math.abs(x - this.startRectangle.x) >= this.paper.options.gridSize
                && Math.abs(y - this.startRectangle.y) >= this.paper.options.gridSize) {

                this.tempRectangleElement = new DrawnRectangle({
                    size: {
                        width: Math.abs(x - this.startRectangle.x),
                        height: Math.abs(y - this.startRectangle.y)
                    },
                    position: {
                        x: this.startRectangle.x > x ? x : this.startRectangle.x,
                        y: this.startRectangle.y > y ? y : this.startRectangle.y
                    },
                });

                // Add the shape to the graph
                this.graph.addCell(this.tempRectangleElement);
            }
        }
    }

    private async addTransaction(source: Node, target: Node, transactionType: TransactionType) {
        // if the source and target are the same, do nothing
        if (source.id != target.id) {

            // Determine success based on transaction type and future state mode
            switch (transactionType) {
                case TransactionType.ServiceFee:
                    this.futureStateMode ? await this.addPaymentByDialog(source, target, PaymentType.ServiceFee, FlowType.Service) : true;
                    break;
                case TransactionType.Royalty:
                    this.futureStateMode ? await this.addPaymentByDialog(source, target, PaymentType.Royalty, FlowType.IPLicense) : true;
                    break;
                case TransactionType.IPTransfer:
                    this.futureStateMode ? await this.addIPTransferByDialog(source, target, FlowType.IPTransfer) : true;
                    break;
                default:
                    throw new Error("Unsupported transaction type");
            }
        }
    }

    private addFlow(source: joint.dia.Element, target: joint.dia.Element, flowType: FlowType, label?: string | null, stepId?: string) {
        if (source.id != target.id) {
            let newLink: joint.shapes.standard.Link;

            switch (flowType) {
                case FlowType.Physical:
                    newLink = new (joint.shapes as any).PhysicalLink();
                    break;
                case FlowType.Legal:
                    newLink = new (joint.shapes as any).LegalLink();
                    break;
                case FlowType.Payment:
                    newLink = new (joint.shapes as any).PaymentLink();
                    break;
                case FlowType.Service:
                    newLink = new (joint.shapes as any).ServiceLink();
                    break;
                case FlowType.IPTransfer:
                    newLink = new (joint.shapes as any).IPTransferLink();
                    break;
                case FlowType.IPLicense:
                    newLink = new (joint.shapes as any).IPLicenseLink();
                    break;
                case FlowType.Agreement:
                    newLink = new (joint.shapes as any).AgreementLink();
                    break;
                case FlowType.Miscellaneous:
                    newLink = new (joint.shapes as any).MiscellaneousLink();
                    break;
            }

            if (stepId) {
                newLink.prop('stepId', stepId);
            }

            newLink.source(source);
            newLink.target(target);
            var sourceEntityIds = (source as Node).entityIds;
            var targetEntityIds = (target as Node).entityIds;
            
            if (!stepId && this.futureStateMode == true) {
                // call C# to add AddRelationshipStep
                stepId = this.dotNetHelper.invokeMethod('AddRelationshipStep', sourceEntityIds, targetEntityIds, newLink.labels()[0]?.attrs.text.text, flowType);
                newLink.prop('stepId', stepId);
            }
            
            this.graph.addCell(newLink);
            
            this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    // called from C#
    private addFlowBetweenEntities(sourceEntityId: string, targetEntityId: string, flowType: FlowType, label: string, stepId: string): void {
        const sourceNode = this.graph.getElements().find(node => node instanceof Node && node.entityIds.includes(sourceEntityId));
        const targetNode = this.graph.getElements().find(node => node instanceof Node && node.entityIds.includes(targetEntityId));

        if (sourceNode && targetNode) {
            this.addFlow(sourceNode, targetNode, flowType, label, stepId);
        }
    }

    // called from C#
    private removeFlowBetweenEntities(sourceEntityId: string, targetEntityId: string, flowType: FlowType): void {
        const links = this.graph.getLinks();
        const linkToRemove = links.filter(link => {
            const sourceNode = link.getSourceElement() as Node;
            const targetNode = link.getTargetElement() as Node;
            const sourceEntityIds = sourceNode.entityIds;
            const targetEntityIds = targetNode.entityIds;
            let linkType;
            switch (flowType) {
                case FlowType.Physical:
                    linkType = 'PhysicalLink';
                    break;
                case FlowType.Legal:
                    linkType = 'LegalLink';
                    break;
                case FlowType.Payment:
                    linkType = 'PaymentLink';
                    break;
                case FlowType.Service:
                    linkType = 'ServiceLink';
                    break;
                case FlowType.IPTransfer:
                    linkType = 'IPTransferLink';
                    break;
                case FlowType.IPLicense:
                    linkType = 'IPLicenseLink';
                    break;
                case FlowType.Agreement:
                    linkType = 'AgreementLink';
                    break;
                case FlowType.Miscellaneous:
                    linkType = 'MiscellaneousLink';
                    break;
            }

            return sourceEntityIds.includes(sourceEntityId) && targetEntityIds.includes(targetEntityId) && link.attributes.type === linkType;
        });

        linkToRemove.forEach(link => {
            link.remove();
        });

        this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
    }

    // Called from C#
    private removeFlowByStepId(stepId: string): void {
        const links = this.graph.getLinks();
        const linksToRemove = links.filter(link => link.prop('stepId') === stepId);
        if (linksToRemove) {
            linksToRemove.forEach(link => link.remove());
            this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    // Called from C#
    private toggleFlowElementVisibility(flowType: string, isVisible: boolean) {
        let elements;
        elements = this.graph.getCells().filter(cell => {
            return cell.attributes.type?.toLowerCase().replace('link', '') === flowType.toLowerCase().replace('link', '');
        });

        elements.forEach(element => {
            // Show/Hide entity cells
            element.attr('body/display', isVisible ? 'block' : 'none');
            const elementView = this.paper.findViewByModel(element);

            if (elementView) {
                const children = elementView.el.querySelectorAll('*');
                children.forEach((child: SVGElement) => {
                    child.setAttribute('display', isVisible ? 'block' : 'none');
                });
            }

            if (element instanceof joint.shapes.standard.Link) {
                element.attr('line/display', isVisible ? 'block' : 'none');
                element.label(0, {
                    attrs: {
                        text: { display: isVisible ? 'block' : 'none' },
                        rect: { display: isVisible ? 'block' : 'none' }
                    }
                });
            }

        });

        this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
    }

    private openMenu(cell: joint.dia.Cell, x: number, y: number) {
        // 1. Create Menu
        var menu = document.createElement('div');
        menu.className = 'flow-editor-menu';

        // 2. Position Menu
        menu.style.top = y + 'px'; //evt.pageY
        menu.style.left = x + 'px'; //evt.pageX

        // 3. Add Menu Options
        var options: MenuOption[] = [];

        if (cell instanceof EmptyElement || cell instanceof EmptyThirdParty) {
            options = [
                //{ text: 'Choose Entity(s)', action: async () => { await this.chooseEntities(cell) } },
                { text: null, action: null },
                { text: 'Delete', action: () => { cell.remove() } },
            ];
        } else if (cell instanceof DrawnRectangle) {
            options = [
                { text: 'Edit Label', action: () => { this.changeElementPrimaryText(cell) } },
                { text: null, action: null },
                { text: 'Delete', action: () => { cell.remove() } },
            ];
        } else if (cell instanceof Node) {
            if (this.futureStateMode) {
                options = [
                    {
                        text: 'Add Transaction', action: null,
                        subMenu: [
                            { text: 'Service Fee', action: () => { this.beginAddTransaction(cell, TransactionType.ServiceFee) } },
                            { text: 'Royalty Fee', action: () => { this.beginAddTransaction(cell, TransactionType.Royalty) } },
                            { text: 'IP Transfer', action: () => { this.beginAddTransaction(cell, TransactionType.IPTransfer) } },
                        ]
                    },
                    { text: 'Move Revenue', action: () => { this.beginMoveRevenue(cell) } },
                    { text: 'Move Expense', action: () => { this.beginMoveExpense(cell) } },
                ];

                if (!(cell instanceof EntityGroup)) {
                    // Ability to add, remove, or edit existing functions
                    options = options.concat([
                        {
                            text: 'Edit Function(s)',
                            action: () => {
                                this.dotNetHelper.invokeMethodAsync('EditEntityFunctions', cell.entityIds[0], cell.functions)
                            }
                        },
                    ]);
                    // Move an existing function
                    if (cell.functions?.length > 0) {
                        options = options.concat([
                            {
                                text: 'Move Function', action: null,
                                subMenu: cell.functions.map((functionName, index) => {
                                    return {
                                        text: functionName,
                                        action: () => { this.beginMoveFunction(cell, functionName) }
                                    };
                                })
                            }
                        ]);
                    }
                    // No existing functions to move
                    else {
                        options = options.concat([
                            {
                                text: 'Move Function', action: () => null,
                                subMenu: [
                                    {
                                        text: 'No functions available', action: () => null
                                    }
                                ]
                            },
                        ]);
                    }
                }

                options.push({ text: null, action: null });
            }

            options = options.concat([
                {
                    text: 'Add Flow', action: null,
                    subMenu: [
                        { text: 'Physical', action: () => { this.beginAddFlow(cell, FlowType.Physical) } },
                        { text: 'Legal Title', action: () => { this.beginAddFlow(cell, FlowType.Legal) } },
                        { text: 'Payment', action: () => { this.beginAddFlow(cell, FlowType.Payment) } },
                        { text: 'Service', action: () => { this.beginAddFlow(cell, FlowType.Service) } },
                        { text: 'IP Transfer', action: () => { this.beginAddFlow(cell, FlowType.IPTransfer) } },
                        { text: 'IP License', action: () => { this.beginAddFlow(cell, FlowType.IPLicense) }},
                        { text: 'Agreement', action: () => { this.beginAddFlow(cell, FlowType.Agreement) } },
                        //TODO: cleanup Miscellaneous flows throughout solution
                        //{ text: 'Miscellaneous', action: () => { this.beginAddFlow(cell, FlowType.Miscellaneous) } },
                    ]
                },
                { text: null, action: null },
            ]);

            if (this.futureStateMode) {
                if (cell.isNewEntity) {
                    options = options.concat([
                        { text: 'Edit P&L', action: () => { this.addPnlByDialog(cell) } }
                    ]);
                }
                options = options.concat([
                    { text: 'Preview P&L', action: () => { this.dotNetHelper.invokeMethodAsync('PreviewPnlByDialog', cell.entityIds, cell.attr('label/text'), null) } },
                ]);
            }

            // TODO: After design is agreed upon, re-implement function icons feature...
            //const functionIconOptions = [
            //    { text: 'Commercial', action: () => { this.changeFunctionIcon(cell, 'Commercial') } },
            //    { text: 'Deliver', action: () => { this.changeFunctionIcon(cell, 'Deliver') } },
            //    { text: 'Develop', action: () => { this.changeFunctionIcon(cell, 'Develop') } },
            //    { text: 'Digital IP', action: () => { this.changeFunctionIcon(cell, 'Digital IP') } },
            //    { text: 'eCommerce', action: () => { this.changeFunctionIcon(cell, 'eCommerce') } },
            //    { text: 'ESG', action: () => { this.changeFunctionIcon(cell, 'ESG') } },
            //    { text: 'Headquarter Oversight', action: () => { this.changeFunctionIcon(cell, 'Headquarter Oversight') } },
            //    { text: 'Internal Support', action: () => { this.changeFunctionIcon(cell, 'Internal Support') } },
            //    { text: 'Logistics', action: () => { this.changeFunctionIcon(cell, 'Logistics') } },
            //    { text: 'Make', action: () => { this.changeFunctionIcon(cell, 'Make') } },
            //    { text: 'Market', action: () => { this.changeFunctionIcon(cell, 'Market') } },
            //    { text: 'Quality', action: () => { this.changeFunctionIcon(cell, 'Quality') } },
            //    { text: 'Regulatory Process', action: () => { this.changeFunctionIcon(cell, 'Regulatory Process') } },
            //    { text: 'Retail', action: () => { this.changeFunctionIcon(cell, 'Retail') } },
            //    { text: 'Sell', action: () => { this.changeFunctionIcon(cell, 'Sell') } },
            //    { text: 'Services', action: () => { this.changeFunctionIcon(cell, 'Services') } },
            //    { text: 'Source', action: () => { this.changeFunctionIcon(cell, 'Source') } },
            //    { text: 'Strategy', action: () => { this.changeFunctionIcon(cell, 'Strategy') } },
            //    { text: 'US Legal IP', action: () => { this.changeFunctionIcon(cell, 'US Legal IP') } },
            //    { text: 'US Newly Developed IP', action: () => { this.changeFunctionIcon(cell, 'US Newly Developed IP') } },
            //    { text: 'Warehousing', action: () => { this.changeFunctionIcon(cell, 'Warehousing') } },
            //    { text: 'Wholesale', action: () => { this.changeFunctionIcon(cell, 'Wholesale') } }
            //];

            //if (cell.attr('functionIcon/xlink:href')) {
            //    functionIconOptions.unshift({ text: 'None', action: () => { this.changeFunctionIcon(cell, 'None') } });
            //}

            options = options.concat([
                { text: 'Edit Color', action: () => { this.changeElementColor(cell) } },
                { text: 'Edit Primary Label', action: () => { this.changeElementPrimaryText(cell) } },
                { text: cell.attr('label2/text') === '' ? 'Add Secondary Label' : 'Edit Secondary Label', action: () => { this.changeElementSecondaryText(cell) } },
                //{
                //    text: cell.attr('functionIcon/xlink:href') === '' ? 'Add Function Icon' : 'Edit Function Icon',
                //    action: null,
                //    subMenu: functionIconOptions
                //},
                { text: null, action: null },
                { text: 'Delete', action: () => { this.confirmDeleteElement(cell) } },
            ]);

        } else if (cell instanceof joint.shapes.standard.Link) {
            var sourceNode = cell.getSourceElement() as Node;
            var targetNode = cell.getTargetElement() as Node;

            options = [
                { text: 'Edit Label', action: () => { this.changeLinkText(cell) } },
                { text: null, action: null },
                { text: 'Delete Flow', action: () => { this.confirmDeleteRelationship(cell) } },
            ];
        }

        options.forEach(option => {
            var menuOption = document.createElement('div');

            if (option.text == null && option.action == null) {
                menuOption.className = 'flow-editor-menu-divider';
                menu.appendChild(menuOption);
                return;
            }

            menuOption.className = 'flow-editor-menu-option';
            var menuText = document.createElement('span');
            menuText.textContent = option.text;
            menuOption.appendChild(menuText);

            if (option.subMenu) {
                var arrow = document.createElement('span');
                arrow.textContent = '>';
                menuOption.appendChild(arrow);
            }

            menu.appendChild(menuOption);

            menuOption.addEventListener('click', async () => {
                if (option.subMenu) {
                    // if there is a sub-menu, open it
                    var bounds = menuOption.getBoundingClientRect();
                    var x = bounds.left + bounds.width;
                    var y = bounds.top;
                    this.openSubMenu(x, y, option.subMenu);
                } else {
                    // else perform the action
                    await option.action();
                    await this.closeMenus();
                }
            });
        });

        // 4. Show Menu
        menu.style.visibility = 'hidden'; // hide the menu to calculate the height
        document.body.appendChild(menu);

        // Calculate the distance between the bottom of the div and the bottom of the window to avoid collisions
        var divBottom = menu.offsetTop + menu.offsetHeight;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var distanceToBottom = windowHeight - divBottom;

        console.debug("divBottom: " + divBottom);
        console.debug("windowHeight: " + windowHeight);
        console.debug("distanceToBottom: " + distanceToBottom);

        // Check if the distance will cause the menu to go off the screen
        if (distanceToBottom < 0) {
            menu.style.top = (menu.offsetTop - menu.offsetHeight) + 'px';
        }
        menu.style.visibility = 'visible';
    }

    public openSubMenu(x: number, y: number, options: MenuOption[]) {
        // close any other sub-menus that are open
        this.closeSubMenus();

        // 1. Create Sub-Menu
        var menu = document.createElement('div');
        menu.className = 'flow-editor-menu';
        menu.classList.add('flow-editor-submenu');

        // 2. Position Menu
        menu.style.top = y + 'px';
        menu.style.left = x + 'px';

        menu.style.maxHeight = '200px';
        menu.style.overflowY = 'scroll';

        // 3. Add Menu Options
        options.forEach(option => {
            var menuOption = document.createElement('div');

            if (option.text == null && option.action == null) {
                menuOption.className = 'flow-editor-menu-divider';
                menu.appendChild(menuOption);
                return;
            }

            menuOption.className = 'flow-editor-menu-option';
            var menuText = document.createElement('span');
            menuText.textContent = option.text;
            menuOption.appendChild(menuText);
            menu.appendChild(menuOption);

            menuOption.addEventListener('click', async () => {
                if (option.subMenu) {
                    // if there is a sub-menu, open it
                    var bounds = menuOption.getBoundingClientRect();
                    var x = bounds.left + bounds.width;
                    var y = bounds.top;
                    this.openSubMenu(x, y, option.subMenu);
                } else {
                    // else perform the action
                    await option.action();
                    await this.closeMenus();
                }
            });
        });

        // 4. Show Menu
        menu.style.visibility = 'hidden'; // hide the menu to calculate the height
        document.body.appendChild(menu);

        // Calculate the distance between the bottom of the div and the bottom of the window to avoid collisions
        var divBottom = menu.offsetTop + menu.offsetHeight;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var distanceToBottom = windowHeight - divBottom;

        console.debug("divBottom: " + divBottom);
        console.debug("windowHeight: " + windowHeight);
        console.debug("distanceToBottom: " + distanceToBottom);

        // Check if the distance will cause the menu to go off the screen
        if (distanceToBottom < 0) {
            menu.style.top = (menu.offsetTop - menu.offsetHeight) + 'px';
        }

        // Calculate the distance between the right of the div and the right of the window to avoid horizontal collisions
        var divRight = menu.offsetLeft + menu.offsetWidth;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var distanceToRight = windowWidth - divRight;


        // Check if the distance will cause the menu to go off the screen
        if (distanceToRight < 0) {
            menu.style.left = (menu.offsetLeft - menu.offsetWidth) + 'px';
        }

        menu.style.visibility = 'visible';
    }

    public closeMenus() {
        // 1. Retrieve all open menus
        var openMenus = Array.from(document.getElementsByClassName('flow-editor-menu'));

        // 2. Close all open menus
        openMenus.forEach(menu => {
            menu.remove();
        });
    }

    public closeSubMenus() {
        // retrieve all open sub-menus
        var openSubMenus = Array.from(document.getElementsByClassName('flow-editor-submenu'));

        // close all open sub-menus
        openSubMenus.forEach(menu => {
            menu.remove();
        });
    }

    private beginAddTransaction(sourceElement: Node, transactionType: TransactionType) {

        // set the activeTool
        this.activeTool = ToolMode.AddTransaction;

        // set context variables
        this.selectedElement = sourceElement;
        this.transactionTypeToCreate = transactionType;

        // add a border filter to the element to indicate that it is selected
        sourceElement.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
        this.closeMenus();
    }

    private beginAddFlow(sourceElement: Node, flowType: FlowType) {
        // set the activeTool
        this.activeTool = ToolMode.AddFlow;

        // set context variables
        this.selectedElement = sourceElement;
        this.flowTypeToCreate = flowType;

        // add a border filter to the element to indicate that it is selected
        sourceElement.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
        this.closeMenus();
    }

    private beginMoveExpense(sourceElement: Node) {
        // set the activeTool
        this.activeTool = ToolMode.MoveExpense;

        // set context variables
        this.selectedElement = sourceElement;

        // add a border filter to the element to indicate that it is selected
        sourceElement.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
        this.closeMenus();
    }

    private beginMoveRevenue(sourceElement: Node) {
        // set the activeTool
        this.activeTool = ToolMode.MoveRevenue;

        // set context variables
        this.selectedElement = sourceElement;

        // add a border filter to the element to indicate that it is selected
        sourceElement.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
        this.closeMenus();
    }

    private beginMoveFunction(sourceElement: Node, functionName: string) {
        // set the activeTool
        this.activeTool = ToolMode.MoveFunction;

        // set context variables
        this.selectedElement = sourceElement;
        this.functionNameToMove = functionName;

        // add a border filter to the element to indicate that it is selected
        sourceElement.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
        this.closeMenus();
    }

    private async addPaymentByDialog(source: Node, target: Node, paymentType: PaymentType, flowType: FlowType): Promise<void> {
        var sourceEntityIds = source.entityIds ?? [];
        var targetEntityIds = target.entityIds ?? [];
        var sourceLabel = source.attr('label/text');
        var targetLabel = target.attr('label/text');

        var dialogResult = await this.dotNetHelper.invokeMethodAsync('AddPaymentStepByDialog', sourceEntityIds, targetEntityIds, sourceLabel, targetLabel, paymentType);

        // Bind new step's ID with the flow/link created
        if (dialogResult) {

            // Determine which step contains the `source` and `target` fields
            const stepWithSourceAndTarget = dialogResult.data.find((step: any) => step.source && step.target);
            const fallbackStep = dialogResult.data.find((step: any) => step.id);

            const stepId = stepWithSourceAndTarget?.id ?? fallbackStep?.id;
            const sourceEntityId = stepWithSourceAndTarget?.source ?? null;
            const targetEntityId = stepWithSourceAndTarget?.target ?? null;

            // Find the source and target nodes
            const sourceNode = this.graph
                .getElements()
                .find((node) => node instanceof Node && sourceEntityId && node.entityIds.includes(sourceEntityId));
            const targetNode = this.graph
                .getElements()
                .find((node) => node instanceof Node && targetEntityId && node.entityIds.includes(targetEntityId));

            if (sourceNode && targetNode) {
                // Add the Service or Royalty flow
                this.addFlow(targetNode, sourceNode, flowType, null, stepId);
                // Add the Payment flow
                this.addFlow(sourceNode, targetNode, FlowType.Payment, null, stepId);
            } else {
                console.error(
                    'Source or target node not found in the graph',
                    { sourceEntityId, targetEntityId }
                );
            }
        }
    }


    private async addIPTransferByDialog(source: Node, target: Node, flowType: FlowType): Promise<void> {
        var sourceEntityIds = source.entityIds ?? [];
        var targetEntityIds = target.entityIds ?? [];
        var sourceLabel = source.attr('label/text');
        var targetLabel = target.attr('label/text');

        var dialogResult = await this.dotNetHelper.invokeMethodAsync('AddIPTransferStepByDialog', sourceEntityIds, targetEntityIds, sourceLabel, targetLabel);

        // Bind new step's ID with the flow/link created
        if (dialogResult) {

            // Find the step with both `source` and `target` fields
            const stepWithSourceAndTarget = dialogResult.data.data.find((step: any) => step.source && step.target);
            const fallbackStep = dialogResult.data.data.find((step: any) => step.id);

            const stepId = stepWithSourceAndTarget?.id ?? fallbackStep?.id;
            const sourceEntityId = stepWithSourceAndTarget?.source ?? fallbackStep?.source ?? null;
            const targetEntityId = stepWithSourceAndTarget?.target ?? fallbackStep?.target ?? null;

            // Find the source and target nodes
            const sourceNode = this.graph
                .getElements()
                .find((node) => node instanceof Node && sourceEntityId && node.entityIds.includes(sourceEntityId));
            const targetNode = this.graph
                .getElements()
                .find((node) => node instanceof Node && targetEntityId && node.entityIds.includes(targetEntityId));

            if (sourceNode && targetNode) {
                // Add the IP Transfer flow
                this.addFlow(targetNode, sourceNode, flowType, null, stepId);
                // Add the Payment flow
                this.addFlow(sourceNode, targetNode, FlowType.Payment, null, stepId);
            } else {
                console.error(
                    'Source or target node not found in the graph',
                    { sourceEntityId, targetEntityId }
                );
            }
        }
    }


    private async addPnlByDialog(node: Node) {
        var entityId = node.entityIds[0]; // there should only be one entity id (for a new entity)

        await this.dotNetHelper.invokeMethodAsync('EditPnlByDialog', entityId);
    }

    public async addEntityNode() {
        console.debug("addEntityNode")

        var entityIdsToExclude: string[] = [];
        this.graph.getElements().forEach(node => {
            if (node instanceof Node) {
                entityIdsToExclude.push(...node.entityIds);
            }
        });

        var results: EntityNodeOptions[] = await this.dotNetHelper.invokeMethodAsync('SearchEntitiesByDialog', entityIdsToExclude);
        if (results != null) {
            var x = (this.container.offsetWidth / 2) - (results.length * 45);
            var y = (this.container.offsetHeight / 2) - (results.length * 45);
            console.debug(results);

            results.forEach(result => {
                // create the new type of element based on the incoming entity(s)
                var newElement: Node = this.CreateEntityNode(result.shape);

                // set the entityIds of the new element
                newElement.entityIds = result.entityIds;

                // set the label of the new element
                newElement.setLabel(result.label);

                // set the color of the new element
                newElement.setColorByJurisdiction(result.jurisdiction);

                newElement.position(x, y);
                newElement.resize(170, 100);
                this.graph.addCell(newElement);

                // set the functions
                if (result.functions && result.functions.length > 0) {
                    newElement.addFunctionIcons(result.functions);
                }

                x = x + 45;
                y = y + 45;
            });

            await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    public async addThirdPartyNode() {
        console.debug("addThirdPartyNode")

        var entityIdsToExclude: string[] = [];
        this.graph.getElements().forEach(node => {
            if (node instanceof Node) {
                entityIdsToExclude.push(...node.entityIds);
            }
        });

        var results: EntityNodeOptions[] = await this.dotNetHelper.invokeMethodAsync('SearchExternalEntitiesByDialog', entityIdsToExclude);
        if (results != null) {
            var x = (this.container.offsetWidth / 2) - (results.length * 45);
            var y = (this.container.offsetHeight / 2) - (results.length * 45);
            console.debug(results);

            results.forEach(result => {
                // create the new type of element based on the incoming entity(s)
                var newElement: Node = this.CreateEntityNode(result.shape);
                // set the entityIds of the new element
                newElement.entityIds = result.entityIds;

                // set the label of the new element
                newElement.setLabel(result.label);

                // set the color of the new element
                newElement.setColorByJurisdiction(result.jurisdiction);

                newElement.position(x, y);
                newElement.resize(170, 100);
                this.graph.addCell(newElement);

                x = x + 45;
                y = y + 45;

            });
            await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    //// TODO: allow changing the entity for a node that was already added.
    //// This should call C# to update any Future State steps that are associated with the existing entity.
    //private async chooseEntities(element: joint.dia.Element) {
    //    // first get all entity ids from all nodes in the graph to exclude from search
    //    var entityIdsToExclude: string[] = [];
    //    this.graph.getElements().forEach(node => {
    //        if (node instanceof Node) {
    //            entityIdsToExclude.push(...node.entityIds);
    //        }
    //    });

    //    var results: EntityNodeOptions[];

    //    if (element instanceof EmptyThirdParty || element instanceof BranchThirdParty) {
    //        results = await this.dotNetHelper.invokeMethodAsync('SearchExternalEntitiesByDialog', entityIdsToExclude);
    //    }
    //    else {
    //        results = await this.dotNetHelper.invokeMethodAsync('SearchEntitiesByDialog', entityIdsToExclude);
    //    }
    //    //console.log('result: ' + result);

    //    if (results != null) {
    //        // do something
    //    }
    //}

    // called from C# 
    public async updateEntityFunctions(entityId: string, functionNames: string[]) {
        var entityNode = this.graph.getElements().find(e => e instanceof Node && e.entityIds.includes(entityId)) as Node;
        console.log(entityNode, functionNames);
        entityNode?.addFunctionIcons(functionNames);
    }

    // called from C# to add a new entity node to the graph
    public async addEntityByEntityNodeOptions(options: EntityNodeOptions) {
        console.debug(options);

        // check if the any of the element ids already exist in the graph
        var element = this.graph.getElements().find(e => e instanceof Node &&
            e.entityIds.filter(id => options.entityIds.includes(id)).length > 0);

        if (element) {
            console.debug('error: cannot add duplicate entity id(s) to graph');
            return;
        }

        var newElement: Node = this.CreateEntityNode(options.shape);

        // set the entityIds of the new element
        newElement.entityIds = options.entityIds;

        // set the label of the new element
        newElement.setLabel(options.label);

        // set the color of the new element
        newElement.setColorByJurisdiction(options.jurisdiction);

        // set the isNewEntity property of the new element
        newElement.isNewEntity = options.isNewEntity;

        // set the position of the new element to the center of the container
        var position = [this.container.offsetWidth / 2, this.container.offsetHeight / 2];
        console.debug(position)

        // check if another entity element is already positioned at the same location
        do {
            var overlappingPositionElement = this.graph.getElements().find(e => e instanceof Node &&
                e.position().x === position[0] &&
                e.position().y === position[1]);

            if (overlappingPositionElement) {
                console.debug('overlapping element')
                position[0] = position[0] + 45;
                position[1] = position[1] + 45;

                console.debug(position)
            }
            console.debug('no overlapping element')
        }
        while (overlappingPositionElement);

        newElement.position(position[0], position[1]);
        newElement.resize(170, 100);

        // Add function icons
        if (options.functions && options.functions.length > 0) {
            await newElement.addFunctionIcons(options.functions);
        }

        this.graph.addCell(newElement);
        await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');


    }

    // called from C# to replace an entity node with a new one
    public async replaceEntityNodeByEntityId(entityId: string, options: EntityNodeOptions) {
        var element = this.graph.getElements().find(e => e instanceof Node &&
            e.entityIds.length == 1 && e.entityIds[0] == entityId);

        if (element) {
            var newElement: Node = this.CreateEntityNode(options.shape);

            // set the entityIds of the new element
            newElement.entityIds = options.entityIds;

            // set the label of the new element
            newElement.setLabel(options.label);

            // set the color of the new element
            newElement.setColorByJurisdiction(options.jurisdiction);

            newElement.position(element.attributes.position.x, element.attributes.position.y);
            newElement.resize(170, 100);
            this.graph.addCell(newElement);

            // set the functions
            if (options.functions && options.functions.length > 0) {
                newElement.addFunctionIcons(options.functions);
            }

            // update any existing links to reference the new element
            var links = this.graph.getConnectedLinks(element);
            links.forEach(link => {
                if (link.get('source').id === element.id) {
                    link.source(newElement);
                }
                if (link.get('target').id === element.id) {
                    link.target(newElement);
                }
            });

            // remove the original element
            element.remove();
            await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    private async confirmDeleteElement(element: joint.dia.Element) {
        if (this.futureStateMode && element instanceof Node) {
            var entityIds = element.entityIds ?? [];
            var label = element.attr('label/text');

            var result = await this.dotNetHelper.invokeMethodAsync('ConfirmDeleteEntityByDialog', entityIds, label);
            if (result) {
                element.remove();
            }
        }
        else {
            element.remove();
        }
        await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
    }

    private async confirmDeleteRelationship(link: joint.dia.Link) {
        if (this.futureStateMode) {
            var sourceNode = link.getSourceElement() as Node;
            var targetNode = link.getTargetElement() as Node;
            var sourceEntityIds = sourceNode.entityIds ?? [];
            var targetEntityIds = targetNode.entityIds ?? [];

            var result = await this.dotNetHelper.invokeMethodAsync('ConfirmDeleteRelationshipByDialog', sourceEntityIds, targetEntityIds);
            if (result) {
                if (!link.prop('stepId')){
                    // if no step id exists on the link, call C# to add RemoveRelationshipStep (in theory this link is from the Current State)
                    // if a step id exists, the link was added by a different step, or is associated with an AddRelationshipStep (added in FS)
                    
                    // TODO: for now we decided not to show these steps because there's no way to visualize it. 
                    //await this.dotNetHelper.invokeMethodAsync('RemoveRelationshipStep', sourceEntityIds, targetEntityIds, link.labels()[0]?.attrs.text.text);
                }
                link.remove();
                await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
            }
        }
        else {
            link.remove();
            await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    // called from C# to remove an entity node from the graph
    public async removeEntityNodeByEntityId(entityId: string) {
        var element = this.graph.getElements().find(e => e instanceof Node &&
            e.entityIds.length == 1 && e.entityIds[0] == entityId);

        if (element) {
            element.remove();
            await this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');
        }
    }

    public async addFunctionToEntityNode(entityId: string, functionName: string) {
        var element = this.graph.getElements().find(e => e instanceof Node &&
            e.entityIds.length == 1 && e.entityIds[0] == entityId) as Node;

        if (element) {
            element.functions.push(functionName);
            this.updateEntityFunctions(entityId, element.functions);
        }
    }

    public async removeFunctionFromEntityNode(entityId: string, functionName: string) {
        var element = this.graph.getElements().find(e => e instanceof Node &&
            e.entityIds.length == 1 && e.entityIds[0] == entityId) as Node;

        if (element) {
            var updatedFunctions = element.functions.filter(f => f !== functionName);
            this.updateEntityFunctions(entityId, updatedFunctions);
        }
    }

    public async onMoveFunctionStepDelete(sourceEntityId: string, targetEntityId: string, functionName: string) {
        this.removeFunctionFromEntityNode(targetEntityId, functionName);
        this.addFunctionToEntityNode(sourceEntityId, functionName);   
    }

    public resetToolMode() {
        this.activeTool = null;

        if (this.selectedElement) {
            this.selectedElement.attr('body/filter', null);
            this.selectedElement = null;
        }

        this.flowTypeToCreate = null;
        this.transactionTypeToCreate = null;

        this.startRectangle = null;
        this.tempRectangleElement?.remove();

        this.graph.getElements().forEach(element => {
            if (element instanceof Node) {
                element.attr('body/filter', null);
            }
        });
    }

    private async changeElementPrimaryText(element: Node | DrawnRectangle) {
        // get the current text of the entity
        var oldText = element.attr('label/text');

        var newText = await this.dotNetHelper.invokeMethodAsync('EditTextByDialog', oldText);
        console.debug('newText: ' + newText);

        // if newText is not null (allow empty strings)
        if (newText != null) {
            element.setLabel(newText);
        }
    }

    private async changeElementSecondaryText(element: Node) {
        // get the current text of the entity
        var oldText = element.attr('label2/text');

        var newText = await this.dotNetHelper.invokeMethodAsync('EditTextByDialog', oldText);
        console.debug('newText: ' + newText);

        // if newText is not null (allow empty strings)
        if (newText != null) {
            element.setSecondaryLabel(newText);
        }
    }

    private async changeFunctionIcon(element: Node, functionName: string) {

        try {

            if (functionName === "None") {
                element.attr('functionIcon/xlink:href', '');
                return;
            }

            const iconPath = `/icons/functions/${functionName}.svg`;
            const response = await fetch(iconPath);

            if (!response.ok) {
                throw new Error(`Error fetching Function Icon path: ${response.status}`);
            }
            const svgContent = await response.text();

            const encodedSvgContent = encodeURIComponent(svgContent);

            if (element.attr('functionIcon')) {
                element.attr({
                    functionIcon: {
                        'xlink:href': `data:image/svg+xml;charset=utf8,${encodedSvgContent}`,
                        width: 30,
                        height: 30,
                        refX: '20%',
                        refY: '10%',
                    }
                });
            } else {
                element.setFunctionIcon(functionName);
            }
        } catch (error) {
            console.error('Error loading or setting SVG:', error);
        }
    }


    private async changeLinkText(link: joint.shapes.standard.Link) {
        // get the current text of the link
        var oldText = link.labels()[0]?.attrs.text.text;
        //var oldLabelPosition = link.labels()[0]?.position;

        var newText = await this.dotNetHelper.invokeMethodAsync('EditTextByDialog', oldText);
        //console.debug('newText: ' + newText);

        // if newText is not null (allow empty strings)
        if (newText != null) {
            link.label(0, {
                attrs: {
                    text: {
                        text: newText
                    }
                }
            });
        }
    }

    private async changeElementColor(element: joint.dia.Element) {
        // get the current color of the entity
        var oldColor = element.attr('body/fill');

        var color = await this.dotNetHelper.invokeMethodAsync('ChooseColorByDialog', oldColor);

        // if color is not null or empty string
        if (color) {
            element.attr('body/fill', color);
        }
    }

    private CreateEntityNode(shapeType: ShapeType): any {
        switch (shapeType) {
            case ShapeType.CorporationCFC:
                return new CorporationCFC();
                break;
            case ShapeType.BranchThirdParty:
                return new BranchThirdParty();
                break;
            case ShapeType.Partnership:
                return new Partnership();
                break;
            case ShapeType.HybridEntity:
                return new HybridEntity();
                break;
            case ShapeType.ReverseHybridEntity:
                return new ReverseHybrid();
                break;
            case ShapeType.DRE:
                return new Disregarded();
                break;
            case ShapeType.Group:
                return new EntityGroup();
                break;
            default:
                return new CorporationCFC();
                break;
        }
    }

    constructor(options: FlowEditorOptions) {
        this.graph = new joint.dia.Graph({}, { cellNamespace: this.namespace });

        this.graph.on('add remove change', (cell) => {
            // if we need to perform some logic when an alement is added, removed, changed
        });

        this.paper = new joint.dia.Paper({
            el: options.container,
            model: this.graph,
            width: null, // this is controlled by CSS
            height: null, // this is controlled by CSS
            gridSize: 15,
            drawGrid: {
                name: 'dot', args: { color: 'black' }
            },
            interactive: {
                linkMove: true,
                labelMove: true,
            },
            snapLabels: true,
            labelsLayer: true,
            moveThreshold: 1,
            restrictTranslate: true,
            cellViewNamespace: this.namespace
        });

        this.dotNetHelper = options.dotNetHelper;
        this.futureStateMode = options.futureStateMode;
        this.container = options.container;

        console.debug('futureStateMode: ' + options.futureStateMode)

        var verticesTool = new joint.linkTools.Vertices({
            snapRadius: 30,
            stopPropagation: false,
        });

        var segmentsTool = new joint.linkTools.Segments({
            snapRadius: 15,
            stopPropagation: false,
        });

        var toolsView = new joint.dia.ToolsView({
            tools: [
                verticesTool,
                segmentsTool,
            ],
        });

        this.paper.on('link:mouseenter', function (linkView) {
            linkView.addTools(toolsView);
        });

        this.paper.on('blank:mouseover', () => {
            this.paper.removeTools();
        });

        this.paper.on({
            'element:mouseenter': (cellView, evt) => {
                //console.log('Element mouse enter');

                if (cellView.model instanceof Node) {
                    if (this.activeTool == ToolMode.AddTransaction || this.activeTool == ToolMode.AddFlow || this.activeTool == ToolMode.MoveExpense || this.activeTool == ToolMode.MoveRevenue || this.activeTool == ToolMode.MoveFunction) {
                        cellView.model.attr('body/filter', { name: 'highlight', args: { color: 'orange', width: 3, opacity: 0.75, blur: 5, } });
                    }
                }
            },
            'element:mouseleave': (cellView, evt) => {
                //console.log('Element mouse leave');

                if (cellView.model instanceof Node) {
                    if (this.selectedElement && this.selectedElement.id != cellView.model.id)
                        cellView.model.attr('body/filter', null);
                }
            },
            'element.pointerup': (cellView, evt) => {
                evt.stopPropagation();
                console.log('Element pointer up');
            },
            'element:pointerclick': async (cellView, evt, x, y) => {
                evt.stopPropagation();
                console.log('Element pointer click - selectedElement is ' + this.selectedElement?.id);
                this.closeMenus();

                const element = cellView.model;

                // If an element is already selected, and it is not the same as this clicked element
                if (this.selectedElement && this.selectedElement != element) {

                    if (this.activeTool == ToolMode.AddTransaction && this.selectedElement instanceof Node && element instanceof Node) {
                        // add a link between the selected element and the current element
                        this.addTransaction(this.selectedElement, element, this.transactionTypeToCreate);
                    } else if (this.activeTool == ToolMode.AddFlow) {
                        // add a link between the selected element and the current element
                        this.addFlow(this.selectedElement, element, this.flowTypeToCreate);
                    } else if (this.activeTool == ToolMode.MoveExpense) {
                        // add a Transfer Expense step between the selected element and the current element
                        var sourceEntityIds = (this.selectedElement as Node).entityIds ?? []
                        var targetEntityIds = (element as Node).entityIds ?? [];
                        var sourceLabel = (this.selectedElement as Node).attr('label/text');
                        var targetLabel = (element as Node).attr('label/text');
                        await this.dotNetHelper.invokeMethodAsync('AddTransferExpenseStepByDialog', sourceEntityIds, targetEntityIds, sourceLabel, targetLabel);
                    } else if (this.activeTool == ToolMode.MoveRevenue) {
                        // add a Transfer Revenue step between the selected element and the current element
                        var sourceEntityIds = (this.selectedElement as Node).entityIds ?? [];
                        var targetEntityIds = (element as Node).entityIds ?? [];
                        var sourceLabel = (this.selectedElement as Node).attr('label/text');
                        var targetLabel = (element as Node).attr('label/text');
                        await this.dotNetHelper.invokeMethodAsync('AddTransferRevenueStepByDialog', sourceEntityIds, targetEntityIds, sourceLabel, targetLabel);
                    } else if (this.activeTool == ToolMode.MoveFunction) {
                        // add a Move Function step between the selected element and the current element
                        var sourceEntityIds = (this.selectedElement as Node).entityIds ?? [];
                        var targetEntityIds = (element as Node).entityIds ?? [];
                        var sourceLabel = (this.selectedElement as Node).attr('label/text');
                        var targetLabel = (element as Node).attr('label/text');
                        await this.dotNetHelper.invokeMethodAsync('AddMoveFunctionStep', sourceEntityIds, targetEntityIds, this.functionNameToMove);
                    }

                    this.dotNetHelper.invokeMethodAsync('SetFlowLegendItems');

                    // reset the selected element
                    this.resetToolMode();
                }
                else { // Else, open a menu for the current element
                    this.openMenu(element, evt.pageX, evt.pageY);
                }
            },
            'link:pointerclick': (cellView, evt) => {
                evt.stopPropagation();
                console.log('Link pointer click');
                this.closeMenus();

                var link = cellView.model;
                this.openMenu(link, evt.pageX, evt.pageY);
            },
            'element:pointermove': (cellView, evt, x, y) => {
                // Logic for handling pointer down events (e.g., drag start)
                //evt.stopPropagation();
                console.log('Element pointer move');
                this.closeMenus();
                this.resetToolMode();
            },
            'element:pointerdblclick': (cellView, evt, x, y) => {
                // Logic for handling double click events on elements
                evt.stopPropagation();
                console.log('Element double click');
                this.closeMenus();
                this.resetToolMode();
            },
            'blank:pointerdown': (evt, x, y) => {
                // Logic for handling pointer down events on the blank paper
                //evt.stopPropagation();
                console.log('Blank pointer down');
                this.closeMenus();

                if (this.activeTool == ToolMode.DrawRectangle) {
                    this.startRectangle = { x: x, y: y };
                    console.debug('starting draw rectangle')
                }
                else {
                    this.resetToolMode();

                    // Logic to start panning the paper
                    var scale = this.paper.scale();
                    this.panStartPosition = { x: x * scale.sx, y: y * scale.sy };
                }
            },
            'blank:pointerup': async (evt, x, y) => {
                // Logic for handling pointer up events on the blank paper
                //evt.stopPropagation();
                console.log('Blank pointer up');
                this.closeMenus();

                if (this.activeTool == ToolMode.DrawRectangle && this.startRectangle) {
                    this.startRectangle = null;
                    this.tempRectangleElement?.setLabel("Click to edit text");
                    this.tempRectangleElement = null;
                    await this.dotNetHelper.invokeMethodAsync('SetRectangleMode', false);
                } else {
                    // Logic to stop panning the paper
                    this.panStartPosition = null;
                }
            },
            'blank:pointermove': (evt, x, y) => {
                // Logic for handling pointer move events on the blank paper
                console.log('Blank pointer move');
                if (this.activeTool == ToolMode.DrawRectangle) {
                    this.drawRectangle(evt, x, y);
                }
            },
            'element:label:pointerclick': (cellView, evt) => {
                evt.stopPropagation();
                console.log('Element label pointer click');
                this.closeMenus();
                this.changeElementPrimaryText(cellView.model);
            },
        });

        // Panning and Zooming
        // General approach from: https://medium.com/@ishaanshettigar/guide-panning-and-zooming-in-jointjs-for-free-step-by-step-tutorial-4a56590d2761

        // handle panning the paper
        // https://stackoverflow.com/questions/28431384/how-to-make-a-paper-draggable
        this.container.addEventListener('mousemove', (evt) => {
            if (this.panStartPosition) {
                this.paper.translate(
                    evt.offsetX - this.panStartPosition.x,
                    evt.offsetY - this.panStartPosition.y);
            }
        });

        // handle zooming the paper
        // https://stackoverflow.com/a/69196310
        this.paper.on("blank:mousewheel", (evt, x, y, delta) => {
            evt.preventDefault();
            const oldscale = this.paper.scale().sx;
            const newscale = oldscale + 0.2 * delta * oldscale

            if (newscale > 0.2 && newscale < 5) {
                this.paper.scale(newscale, newscale);
                this.paper.translate(-x * newscale + evt.offsetX, -y * newscale + evt.offsetY);
            }
        });

        // handle resize of the window
        window.addEventListener('resize', () => {
            console.log('window resize');
            var currentScale = this.paper.scale();
            this.paper.transformToFitContent({ verticalAlign: 'middle', horizontalAlign: 'middle', minScale: currentScale.sx, maxScale: currentScale.sx });
        });
    }

    public dispose() {
        this.closeMenus();
    }
}

export function createFlowEditor(options: FlowEditorOptions): FlowEditor {
    return new FlowEditor(options);
}

export class FlowPreview {
    private readonly namespace = joint.shapes;
    private paper: joint.dia.Paper;
    private graph: joint.dia.Graph;

    constructor(options: FlowPreviewOptions) {
        this.graph = new joint.dia.Graph({}, { cellNamespace: this.namespace });

        this.paper = new joint.dia.Paper({
            el: options.container,
            model: this.graph,
            width: options.container.offsetWidth,
            height: options.container.offsetHeight,
            interactive: false,
            cellViewNamespace: this.namespace,
        });

        this.paper.model.fromJSON(JSON.parse(options.graphJson), { cellNamespace: this.namespace });
        this.paper.transformToFitContent({ verticalAlign: 'middle', horizontalAlign: 'middle', maxScale: 2 });
    }
}

export function createFlowPreview(options) {
    try {
        const graphJson = JSON.parse(options.graphJson);
        return new FlowPreview(options);
    } catch (error) {
        console.error('Invalid JSON input', error);
        console.error('Options:', options);
    }
}


// Define a custom element to represent an Empty Node
export class EmptyElement extends Node {
    defaults() {
        return {
            type: 'EmptyElement',
            svg: '',
            attrs: {
                body: {
                    fill: 'white',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                    class: 'orange-dashed-border',
                },
                label: {
                    //refX: '50%',
                    //refY: '50%',
                    //refDx: 0,
                    //refDy: 0,
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Click to\nchoose Entity(s)',
                }
            },
            markup: [{
                tagName: 'rect',
                selector: 'body'
            },
            {
                tagName: 'text',
                selector: 'label'
            }],
        }
    }
}
Object.assign(joint.shapes, { EmptyElement });

export class EmptyThirdParty extends Node {
    defaults() {
        return {
            type: 'EmptyThirdParty',
            svgPath: '/icons/BranchThirdParty.svg',
            attrs: {
                body: {
                    fill: 'gray',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 'calc(w / 2)',
                    ry: 'calc(h / 2)',
                    cx: 'calc(w / 2)',
                    cy: 'calc(h / 2)',
                    class: 'orange-dashed-border',
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Click to choose\nThird Party(s)',
                }
            },
            markup: [{
                tagName: 'ellipse',
                selector: 'body'
            },
            {
                tagName: 'text',
                selector: 'label'
            }],
        }
    }
}
Object.assign(joint.shapes, { EmptyThirdParty });

// Define a custom element to represent a Corporation/CFC Entity Node
export class CorporationCFC extends Node {
    defaults() {
        return {
            type: 'CorporationCFC',
            svgPath: '/icons/Corporation.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'rect',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }
}
Object.assign(joint.shapes, { CorporationCFC });

// Define a custom element to represent a Branch/Third Party Entity Node
export class BranchThirdParty extends Node {
    defaults() {
        return {
            type: 'BranchThirdParty',
            svgPath: '/icons/BranchThirdParty.svg',
            attrs: {
                body: {
                    fill: 'gray',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 'calc(w / 2)',
                    ry: 'calc(h / 2)',
                    cx: 'calc(w / 2)',
                    cy: 'calc(h / 2)',
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'ellipse',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }

    setColorByJurisdiction(jurisdiction: Jurisdiction) {
        // Third Parties are always gray
        this.setColor(DefaultValues.ThirdPartyColor);
    }
}
Object.assign(joint.shapes, { BranchThirdParty });

// Define a custom element to represent a Partnership Entity Node
export class Partnership extends Node {
    defaults() {
        return {
            type: 'Partnership',
            svgPath: '/icons/Partnership.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    points: '0,calc(h), calc(w/2),0, calc(w),calc(h)'
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    refY: 0.25,
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    refY: 0.25,
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'polygon',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }
}
Object.assign(joint.shapes, { Partnership });


// Define a custom element to represent a Hybrid Entity Node
export class HybridEntity extends Node {
    defaults() {
        return {
            type: 'HybridEntity',
            svgPath: '/icons/HybridEntity.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    d: 'M 0 0 L calc(w) 0 L calc(w) calc(h) L 0 calc(h) Z M 0 calc(h) L calc(w/2) 0 L calc(w) calc(h)'
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'path',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }
}
Object.assign(joint.shapes, { HybridEntity });

// Define a custom element to represent a Reverse Hybrid Entity Node
export class ReverseHybrid extends Node {
    defaults() {
        return {
            type: 'ReverseHybrid',
            svgPath: '/icons/ReverseHybrid.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    transform: 'rotate(180 calc(w/2) calc(h/2))',
                    d: 'M 0 0 L calc(w) 0 L calc(w) calc(h) L 0 calc(h) Z M 0 calc(h) L calc(w/2) 0 L calc(w) calc(h)'
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'path',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }
}
Object.assign(joint.shapes, { ReverseHybrid });

// Define a custom element to represent a DRE Entity Node
export class Disregarded extends Node {
    defaults() {
        return {
            type: 'Disregarded',
            svgPath: '/icons/Disregarded.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                },
                body2: {
                    fill: 'transparent',
                    stroke: 'white',
                    strokeWidth: 2,
                    rx: 'calc(w / 2)',
                    ry: 'calc(h / 2)',
                    cx: 'calc(w / 2)',
                    cy: 'calc(h / 2)',
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: 'Corporation',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'rect',
                selector: 'body'
            }, {
                tagName: 'ellipse',
                selector: 'body2'
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }
}
Object.assign(joint.shapes, { Disregarded });

// Define a custom element to represent an Entity Group Node
export class EntityGroup extends Node {
    defaults() {
        return {
            type: 'EntityGroup',
            svgPath: '/icons/EntityGroup.svg',
            attrs: {
                body: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                    x: -5,
                    y: -5,
                },
                body2: {
                    fill: '#0A2065',
                    stroke: 'white',
                    strokeWidth: 2,
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                    x: 5,
                    y: 5,
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: '',
                },
                label2: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.75*h)',
                    fill: 'white',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    text: '',
                }
            },
            markup: [{
                tagName: 'rect',
                selector: 'body2'
            },
            {
                tagName: 'rect',
                selector: 'body'
            },
            {
                tagName: 'text',
                selector: 'label'
            },
            {
                tagName: 'text',
                selector: 'label2'
            }],
        }
    }

    setColor(color: string) {
        this.attr('body/fill', color);
        this.attr('body2/fill', color);
    }

    setColorByJurisdiction(jurisdiction: Jurisdiction) {
        switch (jurisdiction) {
            case Jurisdiction.US:
                this.setColor(DefaultValues.PrimaryColor);
                break;
            case Jurisdiction.NonUS:
                this.setColor(DefaultValues.SecondaryColor);
                break;
            case Jurisdiction.Mixed:
                this.attr('body/fill', DefaultValues.PrimaryColor);
                this.attr('body2/fill', DefaultValues.SecondaryColor);
                break;
            default:
                this.setColor(DefaultValues.PrimaryColor);
                break;
        }
    }
}
Object.assign(joint.shapes, { EntityGroup });

// Define a custom element to represent a drawn rectangle
export class DrawnRectangle extends joint.shapes.standard.Rectangle {
    defaults() {
        return {
            type: 'DrawnRectangle',
            attrs: {
                body: {
                    fill: 'none',
                    stroke: 'black',
                    strokeWidth: 3,
                    width: 'calc(w)',
                    height: 'calc(h)',
                },
                'label-background': {
                    ref: 'label',
                    fill: 'white',
                    stroke: '#D0D0CE',
                    strokeWidth: 1,
                    width: 'calc(w+12)',
                    height: 'calc(h+10)',
                    x: 'calc(x-6)',
                    y: 'calc(y-5)',
                    rx: 1,
                    ry: 1,
                },
                label: {
                    textAnchor: 'middle',
                    textVerticalAnchor: 'top',
                    x: 'calc(0.5*w)',
                    y: '-7',
                    fill: 'black',
                    fontSize: 14,
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: 'bold',
                    text: '',
                    cursor: 'text',
                    event: 'element:label:pointerclick',
                },
            },
            markup: [{
                tagName: 'rect',
                selector: 'body'
            },
            {
                tagName: 'rect',
                selector: 'label-background'
            }, {
                tagName: 'text',
                selector: 'label'
            }],
        }
    }

    public setLabel(label: string) {
        this.attr('label/text', label);
    }
}

Object.assign(joint.shapes, { DrawnRectangle });

export class PhysicalLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'PhysicalLink',
            color: '#CE1210', // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#CE1210',
                    strokeWidth: 3,
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Physical',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

export class LegalLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'LegalLink',
            color: '#99C13C', // custom attribute - metadata only
            dashed: true, // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#99C13C',
                    strokeWidth: 3,
                    strokeDasharray: '6, 5',
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Legal Title',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

export class PaymentLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'PaymentLink',
            color: '#9B9B9D', // custom attribute - metadata only
            dashed: true, // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#9B9B9D',
                    strokeWidth: 3,
                    strokeDasharray: '6, 5',
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Payment',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

class ServiceLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'ServiceLink',
            color: 'black', // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: 'black',
                    strokeWidth: 3,
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Service',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

class IPTransferLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'IPTransferLink',
            color: '#FFA500', // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#FFA500',
                    strokeWidth: 3,
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'IP Transfer',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

class IPLicenseLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'IPLicenseLink',
            color: '#8E25AB', // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#8E25AB',
                    strokeWidth: 3,
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'IP License',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

class AgreementLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'AgreementLink',
            color: '#256F8C', // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#256F8C',
                    strokeWidth: 3,
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    },
                    sourceMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Agreement',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

class MiscellaneousLink extends joint.shapes.standard.Link {
    defaults() {
        return joint.util.defaultsDeep({
            type: 'MiscellaneousLink',
            color: '#EB8100', // custom attribute - metadata only
            dashed: true, // custom attribute - metadata only
            attrs: {
                line: {
                    stroke: '#EB8100',
                    strokeWidth: 3,
                    strokeDasharray: '6, 5',
                    targetMarker: {
                        d: 'M 10 -5 0 0 10 5 Z',
                    }
                },
            },
            labels: [{
                attrs: {
                    rect: {
                        ref: 'text',
                        width: 'calc(w+12)',
                        height: 'calc(h+10)',
                        x: 'calc(x-6)',
                        y: 'calc(y-5)',
                        stroke: '#D0D0CE',
                        cursor: 'pointer',
                    },
                    text: {
                        textAnchor: 'middle',
                        textVerticalAnchor: 'middle',
                        fill: 'black',
                        fontSize: 14,
                        fontFamily: '"Open Sans", sans-serif',
                        fontWeight: 'bold',
                        text: 'Miscellaneous',
                    }
                }
            }]
        }, joint.shapes.standard.Link.prototype.defaults);
    }
}

Object.assign(joint.shapes, { PhysicalLink, LegalLink, PaymentLink, ServiceLink, IPTransferLink, IPLicenseLink, AgreementLink, MiscellaneousLink });
